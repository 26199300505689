const logosContext = import.meta.globEager("/../img/logos/*.svg");
const externalSourcesContext = import.meta.globEager("/../img/external-sources/*.svg");
const logosContextKeys = Object.keys(logosContext);
const externalSourcesContextKeys = Object.keys(externalSourcesContext);

export const keyToUri = (key) => {
    const fileName = key.toLowerCase().split("/").at(-1);
    const fileNameWithoutExt = fileName.split(".").at(0);
    return fileNameWithoutExt;
};

const fallbackIcon = "Shift";

const map = {};
for (let idx = 0, length = logosContextKeys.length; idx < length; idx++) {
    const item = logosContextKeys[idx];
    map[keyToUri(item)] = item;
}
for (let idx = 0, length = externalSourcesContextKeys.length; idx < length; idx++) {
    const item = externalSourcesContextKeys[idx];
    map[keyToUri(item)] = item;
}

export const uriTokey = (uri) => map[uri.toLowerCase()];

export const externalSourceLogos = (dataSourceName) => {
    let key = uriTokey(`logo-${dataSourceName}`);
    if (externalSourcesContextKeys.indexOf(key) === -1) {
        key = fallbackIcon;
    }
    return externalSourcesContext[key].default;
};

export const logos = (suffix) => {
    const client = window.shiftContext.client;

    let key = uriTokey(suffix ? `${client}_${suffix}` : client);
    if (logosContextKeys.indexOf(key) === -1) {
        key = uriTokey(suffix ? `${fallbackIcon}_${suffix}` : fallbackIcon);
    }

    return logosContext[key].default;
};
